.gust-add-visitor {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .container-row {
    padding-left: 16px;
    padding-right: 16px;

    .instructions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 10px;

      @media screen and (max-width: 993px) {
        flex-direction: row;
      }

      .instructions-item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .instructions-item-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        @media screen and (max-width: 993px) {
          max-width: 50%;
        }

        .title {
          font: normal normal 500 20px/24px 'Work Sans', sans-serif;
          color: #2f7df6;
        }
      }
    }
  }
}
