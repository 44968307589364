.gust-license {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .container-row {
    padding-left: 16px;
    padding-right: 16px;

    .instructions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 10px;

      @media screen and (max-width: 993px) {
        flex-direction: row;
      }

      .instructions-item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media screen and (max-width: 993px) {
          max-width: 50%;
        }

        .instructions-item-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .title {
          font: normal normal 500 20px/24px 'Work Sans', sans-serif;
          color: #2f7df6;
        }
      }
    }
  }

  .header-wrapper {
    margin-bottom: 20px;
  }
}

.license-print-instruction {
  position: absolute;
  z-index: -99999;
  top: -3000px;
  left: -3000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.license-print-instruction-content {
  width: calc(5.5in - 2px);
  height: calc(8.5in - 2px);
  margin: 1px;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;

  font: normal normal 400 14px/18px 'Work Sans', sans-serif;

  .license-print-instruction-header {
    width: 100%;
    height: 0.6in;
    background-color: #000000;
    color: #ffffff;
    display: inline-flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
    }

    div:nth-child(1) {
      margin: 0.02in 0.1in;
      font: normal normal 500 18px/24px 'Work Sans', sans-serif;
    }

    div:nth-child(2) {
      text-align: right;
      margin: 0.02in 0.1in;
      span:nth-child(2) {
        font: normal normal 500 18px/24px 'Work Sans', sans-serif;
      }
    }
  }

  .license-print-instruction-body {
    .name {
      font: normal normal 500 16px/20px 'Work Sans', sans-serif;
      margin-right: 4px;
    }
    display: flex;
    flex-direction: row;

    div:nth-child(1) {
      width: 45%;
      padding: 0 10px;

      div {
        margin: 0.05in 0.1in;
        width: 100%;
        padding: 0;
      }
      .pass-valid-period {
        display: flex;
        flex-direction: column;
        color: #000000;
        font: normal normal 500 24px/28px 'Work Sans', sans-serif;
        .days-of-week {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .field {
        margin: 0.3in 0.1in;
      }
      .rules {
        font: normal normal 500 24px/28px 'Work Sans', sans-serif;
      }
    }
    div:nth-child(2) {
      width: 55%;
      padding: 0 10px;

      .restrictions {
        font-style: italic;
      }
      .directions-details {
        margin-bottom: 10px;
      }
      .directions-title {
        font: normal normal 500 20px/24px 'Work Sans', sans-serif;
      }
      .address1 {
        font: normal normal 500 20px/24px 'Work Sans', sans-serif;
        margin-top: 15px;
      }
      .address2 {
        margin-bottom: 10px;
      }

      div {
        width: 100%;
        padding: 0;

        .step-restrictions {
          font: italic normal 500 14px/18px 'Work Sans', sans-serif;
          margin-top: 10px;
        }
        .step-distance {
          margin-bottom: 10px;
          &.minimize {
            font: italic normal 500 10px/14px 'Work Sans', sans-serif;
          }
        }
        .instruction-step {
          margin-bottom: 0;
          &.minimize {
            font: italic normal 500 10px/14px 'Work Sans', sans-serif;
          }
        }

        div {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .license-print-instruction-footer {
    border-top: 1px solid #000000;
    display: flex;
    flex-direction: row;
    padding-top: 4px;
    margin-top: auto;

    div {
      width: 50%;
      padding: 0 15px;
      img {
        width: 100px;
      }
      svg {
        width: 100px;
      }
    }

    div:nth-child(1) {
      text-align: right;
      padding-top: 7px;
    }
    div:nth-child(2) {
      text-align: left;
    }
  }
}
