.plate-scan-activity-panel {
  position: absolute;
  right: 260px;
  top: 4px;
  z-index: 100009;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  width: 368px;

  @media screen and (max-width: 993px) {
    width: 168px;
  }

  .main-panel {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(134, 140, 147, 0.2);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      width: 100%;
      cursor: default;
      .close-btn {
        cursor: pointer;
        display: flex;
        svg {
          height: 14px;
          width: 14px;
        }
      }
    }
    .traffic-btn {
      justify-content: center;
      display: flex;
    }
    .notifications {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: auto;
      max-height: 320px;
    }
  }
}
